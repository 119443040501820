<template functional>
  <div class="logoContainer" :style="props.style">
    <img class="logoImage" src="../assets/logo.svg" alt="Not found"/>
  </div>
</template>

<script>
export default {
  props: {
    style: {
      type: Object,
      default: () => ({
        width: "50px",
        height: "50px",
      }),
    },
  },
};
</script>

<style lang="scss" scoped>
.logoContainer {
  position: relative;

  * {
    width: 100%;
    height: 100%;
    position: absolute;
  }
}

.logoContainer .logoImage {
  box-shadow: 0 0 0 0 rgba(0, 175, 240, 0.7);
  border-radius: 50%;
  -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
}

@-webkit-keyframes pulse {
  to {
    box-shadow: 0 0 0 15px rgba(0, 175, 240, 0);
  }
}

@-moz-keyframes pulse {
  to {
    box-shadow: 0 0 0 15px rgba(0, 175, 240, 0);
  }
}

@-ms-keyframes pulse {
  to {
    box-shadow: 0 0 0 15px rgba(0, 175, 240, 0);
  }
}

@keyframes pulse {
  to {
    box-shadow: 0 0 0 15px rgba(0, 175, 240, 0);
  }
}
</style>