<template>
  <div>
    <UpdateCustomerForm v-if="ready" :customer="customer"/>
    <div v-else>
      <b-row>
        <b-col/>
        <b-col cols="1">
          <AppPulsingIcon/>
        </b-col>
        <b-col/>
      </b-row>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

import UpdateCustomerForm from "@/components/customers/UpdateCustomerForm";
import AppPulsingIcon from "@/components/AppPulsingIcon";

export default {
  components: {
    UpdateCustomerForm,
    AppPulsingIcon,
  },
  props: {
    uid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      ready: false,
    };
  },
  computed: {
    ...mapState({
      customer: (state) => state.customers.customer,
    }),
  },
  async created() {
    try {
      await this.$store.dispatch("customers/fetchCustomer", this.uid);
    } catch (e) {
      await this.$swal.fire({
        icon: "error",
        title: "Une erreur est survenue lors de la récupération du client",
        text: e.message,
      });
      await this.$router.push({name: 'customers'})
      return;
    }
    this.ready = true;
  },
};
</script>

