<template>
  <b-form @submit.prevent="updateCustomer">
    <b-form-group label="Prénom" label-for="first-name-input">
      <b-form-input
          id="first-name-input"
          v-model="$v.c.firstName.$model"
          type="text"
          required
          placeholder="Renseignez un prénom"
          :state="$v.c.firstName.$dirty ? !$v.c.firstName.$invalid : null"
      />
      <b-form-invalid-feedback :state="!$v.c.firstName.$invalid">
        <span v-if="!$v.c.firstName.required">Vous devez renseigner ce champ</span>
        <span v-else-if="!$v.c.firstName.alpha">Vous devez renseigner un prénom valide</span>
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group label="Nom" label-for="last-name-input">
      <b-form-input
          id="last-name-input"
          v-model="$v.c.lastName.$model"
          type="text"
          required
          placeholder="Renseignez un nom"
          :state="$v.c.lastName.$dirty ? !$v.c.lastName.$invalid : null"
      />
      <b-form-invalid-feedback :state="!$v.c.lastName.$invalid">
        <span v-if="!$v.c.lastName.required">Vous devez renseigner ce champ</span>
        <span v-else-if="!$v.c.lastName.alpha">Vous devez renseigner un nom valide</span>
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group label="Email" label-for="email-input">
      <b-form-input
          id="email-input"
          v-model="$v.c.email.$model"
          type="email"
          required
          placeholder="Renseignez une adresse email"
          :state="$v.c.email.$dirty ? !$v.c.email.$invalid : null"
      />
      <b-form-invalid-feedback :state="!$v.c.email.$invalid">
        <span v-if="!$v.c.email.required">Vous devez renseigner ce champ</span>
        <span v-else-if="!$v.c.email.email">Vous devez renseigner une addresse email valide</span>
      </b-form-invalid-feedback>
    </b-form-group>
    <b-button type="submit" variant="primary" :disabled="$v.$invalid">
      Envoyer
    </b-button>
  </b-form>
</template>

<script>
import {email, required} from "vuelidate/lib/validators";
import {name} from "@/validators";

export default {
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      c: Object.assign({}, this.customer), // copy state to avoid mutation
    };
  },
  validations: {
    c: {
      firstName: {
        required,
        name,
      },
      lastName: {
        required,
        name,
      },
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    updateCustomer() {
      this.$store
          .dispatch("customers/updateCustomer", this.c)
          .then(() => {
            this.$swal.fire({
              icon: "success",
              title: "Client modifié",
            });
          })
          .catch((e) => {
            this.$swal.fire({
              icon: "error",
              title: "Une erreur est survenue lors de la mise à jour du client",
              text: e.message,
            });
          });
    },
  },
};
</script>

